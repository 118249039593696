.comment-box {
    display: flex;
    flex-direction: row;
}

@media (max-width: 600px) {
    .comment-box {
        display: flex;
        flex-direction: column;
    }

    .comment-area-box {
        display: flex;
        width: 90vw;
        margin-left: 5vw;

    }

    .param-select {
        width: 90vw !important;
    }

    .box-title {
        width: 90vw;
    }
}


.parametr-area {
    width: 30vw;
    margin-left: 4vw;
}

.param-select {
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.box-title {
    padding-top: 0;
    font-weight: 600;
}

.comment-area-box {
    height: 50vh !important;
}