.Home {
    display: flex;
}

.logo {
    width: 2vw;
    margin-left: 1vw;
    margin-top: 1vh;
}

.logobox {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 5vh;
}

.logo-text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    font-size: 24px;

    margin-left: 1vw;

}

.help-btn {
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    font-size: 24px;

    margin-left: 70vw;

    text-decoration: none;
}

a:visited {
    color: black;
}